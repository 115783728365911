<template>
  <div id="app">
    <vs-navbar v-if="!hide_navbar_paths.includes($route.path)" color="#1f2d3d" text-color="rgba(255,255,255,.6)"
      active-text-color="rgba(255,255,255,1)" class="myNavbar">
      <div slot="title">
        <vs-navbar-title> <a href="/"> Центр AI-маркетинга</a> </vs-navbar-title>
      </div>
      <vs-navbar-item v-if="$store.state.isLoggedIn === 0" index="12" class="ml-2 mr-2">
        <a href="/auth/login" class="btn btn-primary" size="large">Войти</a>
      </vs-navbar-item>

      <vs-navbar-item v-else index="13" class="ml-2 mr-2">
        <div @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }">
          <div v-on:click="$router.push('/auth/login')">
            {{ $store.state.username }}
          </div>
        </div>
        <div v-on:click="logOut" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }">
          Выйти
        </div>
      </vs-navbar-item>
    </vs-navbar>
    <router-view />
  </div>
</template>

<script>
/**
 * Navbar component
 */

import store from "@/store/store";
import { ShoppingCartIcon, MoonIcon, SunIcon } from "vue-feather-icons";

export default {
  components: {
    ShoppingCartIcon,
    MoonIcon,
    SunIcon,
  },
  data() {
    return {
      hide_navbar_paths: [
        "/wordcloud",
        "/wordcloud-nospellcheck",
        "/wordcloud-nospellcheck-ys-dedup",
        "/wordcloud-nospellcheck-ba-dedup",
        "/wordcloud-nospellcheck-ba-macro-dedup",
        "/llama",
        "/smm",
        "/smm_lk",
        "/smm-chain", "/smm-admin", "/smm-history",
        "/hallucination-fix", "/hallucination-fix-history",
      ],
      navMode: process.env.VUE_APP_NAVIGATION_MODE,
      targetPlatform: process.env.VUE_APP_TARGET_PLATFORM,
      darkTheme: true,
      isCondensed: false,
      hover: false,

      numberOfResumesViewed: 0,
      days_to_expiration: 0,
      numberOfResumesInCart: 0,
      total_balance: 0,

      in_sql: {
        user_id: 0,
        anonymous_id: 0,
        dark_theme: 0,
        isLoggedIn: 0,
      },
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  mounted: () => {

    // TODO: wtf is this
    // var links = document.getElementsByClassName("side-nav-link-ref");
    // var matchingMenuItem = null;
    // for (var i = 0; i < links.length; i++) {
    //   if (window.location.pathname === links[i].pathname) {
    //     matchingMenuItem = links[i];
    //     break;
    //   }
    // }
    // if (matchingMenuItem) {
    //   matchingMenuItem.classList.add("active");
    //   var parent = matchingMenuItem.parentElement;
    //   /**
    //    * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
    //    * We should come up with non hard coded approach
    //    */
    //   if (parent) {
    //     parent.classList.add("active");
    //     const parent2 = parent.parentElement;
    //     if (parent2) {
    //       parent2.classList.add("active");
    //       const parent3 = parent2.parentElement;
    //       if (parent3) {
    //         parent3.classList.add("active");
    //         const parent4 = parent3.parentElement;
    //         if (parent4) {
    //           const parent5 = parent4.parentElement;
    //           parent5.classList.add("active");
    //         }
    //       }
    //     }
    //   }
    // }

  },
  created() {
    console.log("inside mounted navbar0");
    let token_str_flag = this.$cookies.get("access_token_sml");
    console.log("inside navbar: " + token_str_flag);

    let ignore_auth_paths = ['/', '/auth/login', '/register']

    if (token_str_flag !== null) {
      console.log("token:");
      console.log(store.state.isLoggedIn);
      store.commit("UPDATE_TOKEN", this.$cookies.get("access_token_sml"));
      store.commit("UPDATE_USERNAME", this.$cookies.get("username_sml"));
      store.commit("UPDATE_USER_ID", this.$cookies.get("id_sml"));
      //store.commit('Update_showAccountConfirmAlert_State', this.$cookies.get("showAccountConfirmAlert"));
      store.commit("UPDATE_LOGGEDIN_STATE", 1);
      /*if (this.$cookies.get("numberOfResumesViewed")!== null) {
        this.numberOfResumesViewed = this.$cookies.get("numberOfResumesViewed");
      }
      if (this.$cookies.get("expiration_date_milis")!== null) {
        this.expiration_date_milis = this.getDaysToExpiration(this.$cookies.get("expiration_date_milis"));
      }*/
    }
  },
  beforeUpdate() {
    let error_code = this.$route.query.error_code;
    console.log(error_code);
    if (error_code != void 0) {
      if (error_code === '401') {
        this.$vs.notify({
          time: 8000,
          title: 'Ошибка',
          text: 'Войдите в аккаунт для доступа',
          color: 'danger'
        });
      } else if (error_code === '400') {
        this.$vs.notify({
          time: 8000,
          title: 'Ошибка',
          text: 'Войдите в аккаунт для доступа',
          color: 'danger'
        });
      } else if (error_code === '403') {
        this.$vs.notify({
          time: 8000,
          title: 'Ошибка',
          text: 'Недостаточно прав для просмотра',
          color: 'danger'
        });
      } else if (error_code === '404') {
        this.$vs.notify({
          time: 8000,
          title: 'Ошибка',
          text: 'Страница не найдена',
          color: 'danger'
        });
      } else if (error_code >= '500') {
        this.$vs.notify({
          time: 8000,
          title: 'Ошибка',
          text: 'Ошибка сервера',
          color: 'danger'
        });

      }



    }
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },

  methods: {


    logOut() {
      let token = this.$cookies.get("access_token_sml");
      this.$cookies.remove("access_token_sml");
      this.$cookies.remove("username_sml");
      this.$cookies.remove("id_sml");
      this.$cookies.remove("authority_sml");
      //this.$cookies.remove("skillrank_anonymous_id");

      this.axios({
        method: "delete",
        url: "" + this.$hostnamejava + "/oauth/revoke?access_token=" + token,
      })
        .then((res) => {
          console.log("revoke");
        })
        .catch((e) => {
          console.log("error");
        });
      this.$router.push({ path: "/auth/login" });

      this.$store.commit("UPDATE_LOGGEDIN_STATE", 0);

      // console.log('inside logout');
    },

    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;
      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>


<style lang="scss">
.active {
  cursor: pointer;
}
</style>
