/* eslint-disable */
/** @prettier */
/*========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
========================================================================================*/

const mutations = {

  UPDATE_LOGGEDIN_STATE(state, val) {
    state.isLoggedIn = val;
  },
  UPDATE_TOKEN(state, val) {
    state.token = val;
  },

  UPDATE_USERNAME(state, val) {
    state.username = val;
  },
  UPDATE_USER_ID(state, val) {
    state.user_id = val;
  },
  UPDATE_USER_AUTHORITY(state, val) {
    state.user_authority = val;
  },
  Update_justRegistered_State(state, val) {
    state.justRegistered = val;
  },
  Update_passwordChanged_State(state, val) {
    state.passwordChanged = val;
  },
  get_os(state, val) {

    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    this.commit('update_os', os);

  },

  get_browser(state, val){
    var browser = 'n/a';

    if (val.isIE){
      browser = 'IE';
    }
    if (val.isChrome){
      browser = 'Chrome';
    }
    if (val.isFirefox){
      browser = 'Firefox';
    }
    if (val.isOpera){
      browser = 'Opera';
    }
    if (val.isSafari){
      browser = 'Safari';
    }
    if (val.isEdge){
      browser = 'Edge';
    }
    if (val.isChromeIOS){
      browser = 'ChromeiOS';
    }
    if (val.isIOS){
      browser = 'iOS';
    }
    this.commit('update_browser', browser);
    this.commit('update_browser_name', val.meta.name);
    this.commit('update_browser_version', val.meta.version);
    this.commit('update_browser_ua', val.meta.ua);
  },

  get_ip(state, val) {

    val.get({ url: 'https://api.ipify.org/?format=json'})
    .then( (res) => {
      state.ip = res.data.ip;
      //console.log(' ip = ' + ip_addr)
    } );

  },

  get_url(state, val) {

    this.commit('update_page', window.location.pathname);
    this.commit('update_url', window.location.url);
  },



  record_session_event(state, val) {

    if (state.session_status === 0) {
      val.axios_var.get('https://api.ipify.org/?format=json')
          .then((res) => {
            val.input_sql.ip = res.data.ip
            this.commit('update_ip', res.data.ip);
            console.log('ip=' + val.input_sql.ip);
            val.axios_var.post(val.root_var + '/analytics/track-session', val.input_sql)
                .then((res_session) => {
                  this.commit('update_session_hash', res_session.data.session_hash);
                });
          });
    } else {
      val.axios_var.post(val.root_var + '/analytics/track-session', val.input_sql);
    }
  },



      update_session_status(state, val) {
        state.session_status = val;
      },
      update_session_hash(state, val) {
        state.session_hash = val;
      },
      update_anonymous_id(state, val) {
        state.anonymous_id = val;
      },
      update_anonymous_hash(state, val) {
        state.anonymous_hash = val;
      },

      update_ip(state, val) {
        state.ip = val;
      },
      update_os(state, val) {
        state.os = val;
      },
      update_page(state, val) {
        state.page = val;
      },
      update_url(state, val) {
        state.url = val;
      },

      update_browser(state, val) {
        state.browser = val;
      },
      update_browser_name(state, val) {
        state.browser_name = val;
      },
      update_browser_version(state, val) {
        state.browser_version = val;
      },
      update_browser_ua(state, val) {
        state.browser_ua = val;
      },



};

export default mutations;
