/* eslint-disable */
/** @prettier */

/*========================================================================================
  File Name: state.js
  Description: Vuex Store - state
========================================================================================*/


const state = {
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: 'default',

  bodyOverlay: false,
  sidebarItemsMin: false,

  AppActiveUser: {
    id: 0,
    name: 'John Doe',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    img: 'avatar-s-11.png',
    status: 'online'
  },

  isLoggedIn: 0,

  token: '',


  username: 'u',
  user_id: 0,
  //user_authority: 'ROLE_CANDIDATE',
  user_authority: 'ROLE_USER',
  isFirstTimeCareerTree: 1,
  justRegistered: false,

  showRate: false,
  showAccountConfirmAlert: 'yes',
  passwordChanged: false,

  searchParametersState: [],

  ip: '',
  os: '',
  page: '',
  url: '',

  browser: '',
  browser_name: '',
  browser_version: '',
  browser_ua: '',

  session_status: 0,
  session_hash: '',
  anonymous_id: 1,
  anonymous_hash: '',

  number_of_vacancies:0,
  account_type: 'demo',

};

export default state;
